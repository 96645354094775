<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="closeOnClickOutsideModal($event)">
        <div
          class="modal-container"
          :style="{ width: customWidth }"
          :class="setClass"
        >
          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "SimpleModal",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    customWidth: {
      type: String,
    },
    setClass: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    closeOnClickOutsideModal(e) {
      e.stopPropagation();
      if (!e.target.closest(".modal-container")) {
        this.$emit("close", false);
      }
    },
  },
};
</script>

<style scoped lang="less">
/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}

.modal-wrapper {
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  padding: 20px 0 5px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;

  &.premoderation {
    text-align: center;
    border-radius: 8px;
  }
}

.modal-header {
  padding: 0 5px 10px;
  margin-bottom: 9px;
  font-size: 21px;
  text-align: center;
}

.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: table;
  width: 100%;
  height: 100%;
  background-color: var(--base-fade-color);

  &.ws-poll {
    .modal-wrapper {
      transition: opacity 0.3s ease;

      .modal-container {
        width: 90%;
        max-width: 900px;
        height: 90vh;
        max-height: 900px;
        padding: 0;
        overflow: auto;

        .modal-header {
          overflow: auto;
        }
      }
    }
  }
}

.modal-body {
  position: relative;
}

.modal-default-button {
  float: right;
}
</style>
